<template>
    <div v-if="ready" class="fill-height">
        <subpage-top-bar>Статистика от educont</subpage-top-bar>
        
        <v-container fluid class="fill-height pt-0 pb-0">
            <v-row
                v-if="!errors"
                justify="start"
                align="stretch"
                class="fill-height overflow-hidden"
            >
                <v-col v-if="ready" :cols="12" class="fill-height overflow-auto">
                    <v-row class="d-flex align-center">
                        <v-col cols="3">
                            <v-select
                                v-model="filter.status"
                                :items="filterStatuses"
                                label="Статус"
                            ></v-select>
                        </v-col>
                        <v-col cols="1">
                            <v-btn
                                color="purple"
                                dark
                                @click.stop.prevent="fetch"
                            >Фильтр</v-btn>
                        </v-col>
                        <v-col cols="1">
                            <v-btn @click.stop.prevent="resetFetch">Сброс</v-btn>
                        </v-col>
                        <v-spacer />
                        <v-col cols="2">
                            <v-btn @click="resendTransactions">
                                <v-icon>mdi-reload</v-icon> Переотправить
                            </v-btn>
                        </v-col>
                    </v-row>

                    <v-divider />

                    <v-simple-table>
                        <template v-slot:default>
                        <thead>
                            <tr>
                                <th></th>
                                <th class="text-left">
                                    ID
                                </th>
                                <th class="text-left">
                                    Статус
                                </th>
                                <th class="text-left">
                                    Ошибки
                                </th>
                                <th class="text-left">
                                    Лог
                                </th>
                                <th class="text-left">
                                    Создан
                                </th>
                                <th class="text-left">
                                    Обновлён
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr
                                v-for="item in items"
                                :key="item.id"
                            >
                                <td>
                                    <v-checkbox
                                        v-model="item.checked"
                                        :disabled="![statuses.WITH_ERRORS.value, statuses.FAILED.value].includes(item.transactionStatus)"
                                    />

                                </td>
                                <td>{{ item.transactionId }}</td>
                                <td v-if="!item.transactionStatus">&nbsp;</td>
                                <td v-else :title="statuses[item.transactionStatus].hint">
                                    <v-chip :color="statuses[item.transactionStatus].color"></v-chip>
                                    {{ statuses[item.transactionStatus].value }}
                                </td>
                                <td class="td-errors">{{ item.errors || '-' }}</td>
                                <td class="td-errors">{{ item.log || '-' }}</td>
                                <td>{{ formatDateTime(item.created_at) }}</td>
                                <td>{{ formatDateTime(item.updated_at) }}</td>
                            </tr>
                        </tbody>
                        </template>
                    </v-simple-table>

                    <v-pagination
                        v-model="page"
                        :length="Math.ceil(totalItems / pageSize)"
                        :total-visible="5"
                        circle
                        class="my-4"
                        @input="fetch"
                    ></v-pagination>
                </v-col>
            </v-row>

            <div v-else class="mx-auto">
                <v-alert type="error">
                    {{ errors || 'Произошла ошибка на сервере. Обратитесь за помощью к администратору.' }}
                </v-alert>

                <div
                    v-if="allowEdit"
                    class="w-100 d-flex justify-center align-center"
                >
                    <v-btn class="mx-auto" @click="resetError">
                        <v-icon class="mr-1">mdi-keyboard-backspace</v-icon>
                        Отмена
                    </v-btn>
                </div>
            </div>
        </v-container>
    </div>
    <!-- Loading icon -->
    <div
        v-else
        class="
            wv-100
            vh-100
            fill-height
            d-flex
            flex-row
            justify-center
            align-center
        "
    >
        <v-progress-circular
            :size="70"
            :width="7"
            color="purple darken-3"
            indeterminate
        />
    </div>
</template>
<script>
import SubpageTopBar from '@/components/template/SubpageTopBar';
import moment from 'moment';
import { mapGetters } from "vuex";

export default {
    name: "Stats",
    components: { SubpageTopBar },
    data () {
        return {
            ready: false,
            loading: false,
            filter: null,
            items: [],
            errors: null,
            // Pagination
            page: 1,
            pageSize: 20,
            totalItems: 0,
        };
    },
    computed: {
        ...mapGetters("user", ["allowEdit"]),
        statuses () {
            return {
                COMPLETED: { value: 'COMPLETED', color: 'green', hint: 'Статистика успешно загружена.' },
                IN_WAITING: { value: 'IN_WAITING', color: 'grey',hint: 'Статистика находится в очереди на обработку, требуется отправить запрос на получение статуса обработки позднее.' },
                IN_PROCESSING: { value: 'IN_PROCESSING', color: 'orange',hint: 'Статистика получена из очереди, находится в процессе обработки, требуется отправить запрос на получение статуса обработки позднее.' },
                WITH_ERRORS: { value: 'WITH_ERRORS', color: 'red',hint: 'Статистика успешно загружена, но есть ошибочные записи, которые были отклонены, необходимо их проверить вручную и если требуется, то перезагрузить' },
                FAILED: { value: 'FAILED', color: 'black',hint: 'Статистика отклонена по технической причине, необходимо перезагрузить' },
                RESUBMITTED: { value: 'RESUBMITTED', color: 'yellow',hint: 'Транзакция переотправлена вручную. На основании записей текущей транзакции создана новая. Дальнейшие действия над текущей транзакцией невозможны.' }
            }
        },
        filterStatuses () {
            return Object.keys(this.statuses).map(key => this.statuses[key].value)
        }
    },
    methods: {
        async fetch() {
            this.loading = true;
            const { data } = await this.$store.dispatch('stats/transactionList', {
                filter: {transactionStatus: this.filter.status},
                pagination: {pageSize: this.pageSize, page: this.page - 1}
            });
            
            this.items = data.items.map(_item => {
                return {
                    ..._item,
                    checked: false
                }
            });
            this.totalItems = data.total
            this.loading = false;
        },
        formatDateTime (timestamp) {
            return moment(timestamp, "X").format("YYYY-MM-DD HH:mm:ss")
        },
        async resetFetch () {
            this.resetFilter()
            await this.fetch()
        },
        resetFilter () {
            this.filter = {
                status: undefined
            }
        },
        resetError () {
            this.errors = null;
        },
        async resendTransactions () {
            const checkedItems = this.items.filter(item => item.checked);

            if (!checkedItems.length) {
                alert('Ошибка. Не выбрано ни одной транзакции.');
                return;
            }
            const confirmText = `Внимание! Вы точно хотите повторно отправить на проверку данные из выбранных транзакций? Отменить это действие будет невозможно!`;
            try {
                if (!confirm(confirmText)) { return }
                this.loading = true;
                const result = await this.$store.dispatch('stats/resend', { ids: checkedItems.map(item => item.transactionId) });
                result ? this.fetch() : alert('Неизвестная ошибка!');
            } catch (e) {
                console.error(e);
            } finally {
                this.loading = false;
            }
        }
    },
    async created () {
        try {
            if (!this.allowEdit) {
                throw new Error('У вас недостаточно прав для доступа к этому разделу.');
            }
            // Fetch data 
            await this.resetFetch();

        } catch (e) {
            console.error(e);
            this.errors = e.message;
        } finally {
            this.ready = true;
        }
    }
};
</script>

<style lang="scss" scoped>
.td-errors {
    max-width: 250px;
    white-space: nowrap;
    overflow-x: auto;
}
</style>